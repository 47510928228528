import * as PIXI from 'pixi.js';
import ResourceService from "../../../services/ResourceService";
import { AppConfig } from '../../../config/AppConfig';



class GradientFill extends PIXI.Sprite  {
    /**
     * @param {*} colors : Array<string> | string 
     * @param {*} angle : number
     */
    constructor(colors, angle) {
        super();
        this.colors = colors;
        this.texture = this.createGradTexture();
    }

    createGradTexture() {
        const quality = 600;
        const canvas = document.createElement('canvas');
        canvas.width = 200;
        canvas.height = quality;

        const ctx = canvas.getContext('2d');
        const grd = ctx.createLinearGradient(0, 0, 0, quality);

        if (Array.isArray(this.colors)) {
            // Handle array input as before
            for (let i = 0; i < this.colors.length; i++) {
                grd.addColorStop(i / (this.colors.length - 1), this.colors[i]);
            }
        } else if (typeof this.colors === 'string') {
            // Handle string input with parsing and interpolation
            const colorEntries = this.colors.split(',').map(entry => entry.trim());
            const parsedColors = colorEntries.map(entry => {
                const [color, position] = entry.split(' ').map(str => str.trim());
                const pos = parseFloat(position) / 100; // Convert to decimal
                return { color, pos };
            });

            // Filter out invalid stops and apply interpolation for boundary cases
            const validColors = parsedColors.filter(({ pos }) => pos >= 0 && pos <= 1);
            
            if (validColors.length === 0) {
                throw new Error('No valid color stops within range (0, 1)');
            }

            // Add color stops, interpolating if needed at edges
            validColors.forEach(({ color, pos }, index) => {
                if (index === 0 && pos > 0) {
                    grd.addColorStop(0, validColors[0].color); // Set the first color at 0
                }
                grd.addColorStop(pos, color);
                if (index === validColors.length - 1 && pos < 1) {
                    grd.addColorStop(1, validColors[validColors.length - 1].color); // Set last color at 1
                }
            });
        }

        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 200, quality);

        return PIXI.Texture.from(canvas);
    }
}



export default GradientFill

