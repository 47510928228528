import * as PIXI from 'pixi.js';
import SpriteCommon from "../common/SpriteCommon";
import ResourceList from "../../../services/ResourceList";
import { Container, Matrix, Point } from "pixi.js";
import gsap from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import ClonedItemSprite from "./ClonedItemSprite";
import { AppConfig } from "../../../config/AppConfig";
import EItemsID from '../../../model/EItemsID';
import MagnetEffect from '../effects/MagnetEfffect';
import EItemType from '../../../model/EItemType';
import ParticleEmtter from '../effects/ParticleEmitter';

const { itemJumpDuration, itemDropDuration, displayItemsInCart } = AppConfig.animationSettings;
export class Cart extends SpriteCommon {
    /**
     * @param { GameModel } gameModel
     */
    constructor(gameModel) {
        super(ResourceList.CART);
        this.gameModel = gameModel
        this.itemsCont = new Container();

        this.anymateInCartStart = (clone) => {
            gsap.to(clone, {
                x: 0,
                y: -270,
                rotation: Math.random() * Math.PI / 2 - Math.PI / 4,
                onComplete: this.anymateInCartFinish,
                onCompleteParams:[clone],
                ease: "power2.out",
                duration: itemJumpDuration / 3
            });
        };

        this.anymateInCartFinish = (clone) => {
                gsap.to(clone, {
                x: clone.inCartPoint.x,
                y: clone.inCartPoint.y,
                rotation: Math.random() * Math.PI / 2 - Math.PI / 4,
                ease: "power2.in",
                delay: 0,
                duration: itemDropDuration,
                  onComplete: () => {
                    if (clone.itemKind && clone.itemKind.itemType !== EItemType.SCORES) {
                      this.itemsCont.removeChild(clone);
                    }
                  },
            });
            gsap.to(clone.scale, {
                x: 0.7,
                y: 0.7,
                ease: "power2.in",
                duration: itemDropDuration
            });
        };

        gsap.registerPlugin(PixiPlugin);
        this.addChild(this.itemsCont);

    }

    cloneItem(item) {
        const destinationContainer = this;
        const totalGoodProducts = this.gameModel.totalGoodProductCount;
        const inCartPoint = this.getTargetPoint(totalGoodProducts);
        const clone = new ClonedItemSprite(item.resourceName, inCartPoint);
        clone.itemKind = item.itemKind
        const sourceMatrix = item.transform.worldTransform.clone();
        clone.anchor.set(item.anchor.x, item.anchor.y);
        let reverseTransformMatrix = new Matrix();

        let currentContainer = destinationContainer;
        while (currentContainer) {
            reverseTransformMatrix = reverseTransformMatrix.append(currentContainer.transform.worldTransform.clone().invert());
            currentContainer = currentContainer.parent;
        }

        sourceMatrix.prepend(reverseTransformMatrix);
        clone.transform.setFromMatrix(sourceMatrix);
        this.itemsCont.addChild(clone);
        this.anymateInCartFinish(clone);
        this.removeExtraItems();
    }

    getTargetPoint(totalItems) {
        let yDisplace = 0;
        if (totalItems < 20) {
            yDisplace = 50 + totalItems * 5;
        } else {
            yDisplace = 50 + 20 * 5;;
        }
        const p = new Point();
        p.x = Math.random() * 150 - 75 - 15;
        p.y = Math.random() * 50 - 100 - yDisplace;
        return p;
    }

    removeExtraItems() {
        while (this.itemsCont.children.length > displayItemsInCart) {
            this.itemsCont.removeChildAt(0);
        }
    }

    removeAllItems() {
        // this.itemsCont.removeChildren();
        let n = 0;
        this.itemsCont.children.forEach(element => {

            gsap.to(element, {
                x: -400,
                y: -400,
                alpha: 0,
                rotation: Math.random() * Math.PI / 2 - Math.PI / 4,
                ease: "power2.in",
                delay: n * 0.1,
                duration: 0.5,
                onComplete: () => {
                    this.itemsCont.removeChild(element);
                }
            });
            n++

        });

    }
}
export class CartOver extends SpriteCommon {
    constructor(gameModel) {
        super(ResourceList["CART_OVER_" + AppConfig.lang]);
        this.gameModel = gameModel;

        this.magnetIcon = new SpriteCommon(ResourceList.ITEM_MAGNET);
        this.speedUpIcon = new SpriteCommon(ResourceList.ITEM_SPEEDUP);
        this.speedUpEffect = this.addSpeedUpEffect();
        this.magnetEffect = new MagnetEffect();
        this.alpha = 0.7;

      this.addChild(this.speedUpIcon);
        this.addChild(this.magnetIcon);
        this.addChild(this.magnetEffect);
        this.addChild(this.speedUpEffect);
        this.speedUpEffect.y = -50;
        this.magnetEffect.y = - 150;

        this.magnetIcon.anchor.set(0.5);
        this.speedUpIcon.anchor.set(0.5);

        this.speedUpIcon.y = - 150;
        this.magnetIcon.y = - 150;
        this.magnetEffect.y = - 150;

        this.magnetIcon.anchor.set(0.5, 0.5);
        this.speedUpIcon.anchor.set(0.5, 0.5);

        this.speedUpIcon.visible = false;
        this.magnetIcon.visible = false;
        this.magnetEffect.visible = false;

        this.t = 0;

        this.gameModel.extraStatusUpdated.add((extraID, isOn) => {
            if (extraID === EItemsID.SPEED_UP){
                this.speedUpIcon.visible = isOn;
                if (isOn) {
                  this.speedUpEffect.start(0, 0, 2, 'shockFX');
                }
      else {
                  this.speedUpEffect.stop('shockFX');
                }
            }
            if (extraID === EItemsID.MAGNET){
                this.magnetIcon.visible = isOn;
                this.magnetEffect.visible = isOn;
            }
        });
    }
  addSpeedUpEffect() {
    const shockTexArray= new Array(6).fill(0).map((el, i) => {
      const link = `./assets/misc/shock_${i}.png`;
      return PIXI.Texture.from(link);
    })

    const emitter = new ParticleEmtter();

    emitter.add('shockFX', {
      life: [0.1, 0.4],
      birthrate: 0.2,
      width: 150,
      height: 70,
      colors: [0xffffff],
      textures: [shockTexArray],
      animatedTextureFPS: 10,
      animatedTextureRandom: 6,
      scale: [10, 70],
      rotation: [-Math.PI, Math.PI],
      velocity: 0,
    })

    return emitter;
  }

    animate() {
        this.t += 0.1;
        this.magnetIcon.x =  8 * Math.cos(this.t) * Math.sin(this.t);
        this.magnetIcon.y =  6 * Math.sin(this.t) - 150;
        const sc = 0.5 +  Math.sin(this.t) / 20;
        this.magnetIcon.scale.set(sc, sc);

        this.speedUpIcon.x =  8 * Math.cos(this.t + Math.PI) * Math.sin(this.t);
        this.speedUpIcon.y =  8 * Math.sin(this.t + Math.PI) - 150;

        this.speedUpIcon.scale.set(sc, sc);
        if (this.magnetEffect.visible) this.magnetEffect.update(this.t);
    }
}
