import { AppConfig } from "../config/AppConfig";
import EGameNames from "../config/EGameNames";
import Background from "./components/backgrounds/Background";
import BackgroundG2 from "./components/backgrounds/BackgroundG2";
import BackgroundG3 from "./components/backgrounds/BackgroundG3";
import BackgroundG4 from "./components/backgrounds/BackgroundG4";

class SkinClassesFactory {

    /**
     * @access public 
     */
   static getCurrentBackgroundClass() {
        const gameID = AppConfig.gameID;
        const backgroundClassMapping = {
            [EGameNames.CINEPLEX_G1]: Background,
            [EGameNames.EMPIRE_G2]: BackgroundG2,
            [EGameNames.SCOTIA_G3]: BackgroundG3,
            [EGameNames.SCENEPLUS_G4]: BackgroundG4,
        };
        return backgroundClassMapping[gameID] || null;
    }
}

export default SkinClassesFactory



