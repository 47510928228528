import ResourceList from "../../../services/ResourceList";
import InfoBox from "./InfoBox";
import InfoBoxWithIcon from "./InfoBoxWithIcon";

class ScoreInfo extends InfoBoxWithIcon {
    constructor() {
        super(ResourceList.MSC_COIN);
    }
}

export default ScoreInfo