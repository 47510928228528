import * as PIXI from 'pixi.js';
import ResourceList from '../../../services/ResourceList';
import Particle from './Particle';
import { AppConfig } from '../../../config/AppConfig';
class Fireworks extends PIXI.Container  {
    constructor(gameScreen) {
        super();
        this.textureList = [
            ResourceList.BG_1_BUBBLE_1,
            ResourceList.BG_1_BUBBLE_1,
            ResourceList.BG_1_BUBBLE_1,
            ResourceList.BG_1_BUBBLE_1,
            ResourceList.BG_1_BUBBLE_1,
            ResourceList.BG_1_BUBBLE_2,
            ResourceList.BG_1_BUBBLE_2,
            ResourceList.BG_1_BUBBLE_2,
            ResourceList.BG_1_BUBBLE_3,
            ResourceList.BG_1_BUBBLE_3,
            ResourceList.BG_1_HEART
        ]
        this.particlesStack = 0;
        this.emitters = new Array();
        this.emitterX = 0;
        this.emitterY = 0;
        this.bornVariateX = 50;
        this.bornVariateY = 0;
        this.emitterDisplaceX = 0;
        this.emitterDisplaceY = 0;
        this.emitterAngle = 0;
        this.frameCounter = 0;
        this.addOnEachFrame = 20;
        this.allowNewParticles = true;
        this.t = 0;
        this.angleTarget = Math.PI * 3 / 2;
        this.angleIncr = 0;
        this.angleAcc = 0;


        // this.addParticle();
        // this.addParticle();
    }

    /**
     * @access public - update on each frame
     */
    update() {
        this.frameCounter ++;

        this.angleAcc = Particle.randRange(-0.005, 0.005);
        this.angleIncr += this.angleAcc;
        this.angleTarget += this.angleIncr;



        if (this.allowNewParticles) {
            if (this.addOnEachFrame >= 1){
                if (this.frameCounter % this.addOnEachFrame === 0) {
                    if (Math.random() < 0.5)  this.addParticle();
                   
                }
            } else {
                const n = 1 / this.addOnEachFrame;
                for (let i = 0; i < n; i++) {
                    this.addParticle();               
                }
            }
        }
        for (const p of this.children) {
            if (p instanceof Particle){
                p.update();
                if (p.t >= p.lifeTime) this.removeChild(p);
            }
        }
    }

    /**
     * @access public
     * @param {Sprite} emitter a Sprite with emitterDisplaceX and emitterDisplaceY protperties
     * @param {number} displaceX 
     * @param {number} displaceY 
     */
    addEmmiter(emitter, displaceX, displaceY) {
        
        emitter.emitterDisplaceX = displaceX;
        emitter.emitterDisplaceY = displaceY;
        if (this.emitters.indexOf(emitter) >  -1) return
        emitter.on('stopEmitting', () => {
            this.removeEmmiter(emitter);
        });
        this.emitters.push(emitter);
    }

    /**
     * @access public
     * @param {Sprite} emitter 
     */
    removeEmmiter(emitter) {
        this.emitters = this.emitters.filter(item => item !== emitter);
    }

    /**
     * @access public
     */
    removeAllEmmiters(emitter) {
        this.emitters.forEach(element => {
            this.removeEmmiter(element);
        });
    }

    addParticle() {
            // if (this.particlesStack <= 0) return
            const { gameWidth, gameHeight } = AppConfig.settings;
            this.particlesStack--;
            // const angle = Particle.randRange(- Math.PI, Math.PI);
            const textureName = this.getRandomItem(this.textureList);
            const angle = - Math.PI;
            const colorRange = 0xffffff - 0xeeeeee;
            const colorIncr = Math.floor(Math.abs(Math.sin(this.frameCounter / 1000) * colorRange) * 0.05);
            const colorTint = 0xffffff - colorIncr;
            const rSpeed = Particle.randRange(- Math.PI / 60, Math.PI / 60);
            // const rSpeed = 0;
            let speedAbs, lifeTime;
            if (textureName === ResourceList.BG_1_HEART) {
                speedAbs = Particle.randRange( 5.8, 7.8);
                lifeTime = 190;
            } else {
                speedAbs = Particle.randRange( 1.8, 2.8);
                lifeTime = 560;
            }
            
            const bornX = Particle.randRange(-this.bornVariateX, this.bornVariateX);
            const bornY = Particle.randRange(-this.bornVariateY, this.bornVariateY)
            // const posX = this.emitterX + this.emitterDisplaceX + bornX;
            // const posY = this.emitterY + this.emitterDisplaceY + bornY;

            const isLeft = Math.random() < 0.5;
            const posX = isLeft ? Math.random() * gameWidth / 3 : gameWidth - Math.random() * gameWidth / 3;
            const posY = gameHeight - Math.random() * gameHeight / 2;

            const sc = Particle.randRange(0.1, 0.5);

            const p = new Particle(this, textureName,
                posX,  posY, angle, speedAbs, rSpeed, sc, lifeTime);
            // p.tint = colorTint;
            
            // p.scale.set(sc);
            // p.alpha = 0.5;
            // p.blendMode = "luminosity";
            // p.blendMode = PIXI.BLEND_MODES.OVERLAY;
            // p.blendMode = 7;
            p.alpha = 0.9;
            this.addChild(p);

    }


    addParticleToEmitters() {
        this.emitters.forEach((emitter) => {
            if (emitter && emitter?.position){
                const angle = 0;
                const rSpeed = Particle.randRange(- Math.PI / 60, Math.PI / 60);
                // const rSpeed = 0;
                const speedAbs = Particle.randRange( 0.1, 1.8);
                const posX = emitter.x + emitter.emitterDisplaceX;
                const posY = emitter.y + emitter.emitterDisplaceY;
                const p = new Particle(this, ResourceList.NOTE_1, posX, posY,
                    angle, speedAbs, rSpeed, 50);
                const sc = Particle.randRange(0.1, 0.1);
                p.scale.set(sc);
                this.addChild(p);
            } else {
                this.removeEmmiter(emitter);
            }

        });  

    }

    /*
    addParticle() {
        this.emitters.forEach((emitter) => {
            const angle = Particle.randRange(- Math.PI, Math.PI);
            const rSpeed = Particle.randRange(- Math.PI / 60, Math.PI / 60);
            const speedAbs = Particle.randRange( 2, 8);
            const p = new Particle(this, ResourceList.NOTE_1, this.emitterX, this.emitterY.y,
                angle, speedAbs, rSpeed, 30);
            const sc = Particle.randRange( 0.7, 1.6);
            p.scale.set(sc);
            this.addChild(p);
        });  

    }
        */

    /**
     * 
     * @param {number} posX 
     * @param {number} posY 
     */

    setEmmiterPos(posX, posY) {
        this.emitterX = posX;
        this.emitterY = posY;
    }

    setEmmiterAngle(angle, radius) {
        this.emitterDisplaceX = angle * Math.cos(angle) + radius;
        this.emitterDisplaceY = angle * Math.cos(angle) + radius;
        this.angle = angle;
    }

    getRandomItem(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

}

export default Fireworks