import * as PIXI from 'pixi.js';
import ResourceService from "../../../services/ResourceService";
import { AppConfig } from '../../../config/AppConfig';
import GradientFill from './GradientFill';

class FullScreenGradient extends GradientFill  {
    constructor(colors, angle, gameScreen) {
        super(colors, angle);
        this.gameScreen = gameScreen;
        AppConfig.sizeUpdated.add(this.onResize.bind(this));
        this.onResize();
    }



    /**
     * @access public
     */
    onResize() {
        const { gameWidth, gameHeight } = AppConfig.settings;

        /*
        let gameWidth, gameHeight;
        
        if (!AppConfig.SCALE_SCENE) {
            gameWidth = AppConfig.settings.gameWidth;
            gameHeight = AppConfig.settings.gameHeight;
        } else {
            const sc =  AppConfig.settings.gameHeight / AppConfig.base.HEIGHT;
            gameWidth = AppConfig.settings.gameWidth / sc;
            gameHeight = AppConfig.settings.gameHeight / sc;

            this.x = - (this.gameScreen.x / sc) ; 
        }
            */

        this.width = gameWidth;
        this.height = gameHeight;
    }
}

export default FullScreenGradient

