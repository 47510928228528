import { AppConfig } from "../../config/AppConfig";
import GameModel from "../../model/GameModel";
import EMessages from "../../services/EMessages";
import ResourceList from "../../services/ResourceList";
import SpriteCommon from "../components/common/SpriteCommon";
import gsap from 'gsap';

class ReglesDOM {
    /**
     * 
     * @param {GameModel} gameModel 
     */
    constructor(gameModel) {
        // super(ResourceList.RULES);
        this.gameModel = gameModel;
        this._visible = undefined;
        const lang = AppConfig.lang.toLowerCase();
        this.domLink = document.getElementById('tutorial' + AppConfig.gameID + lang);
        this.closeBtnDOM = document.querySelector('#tutorial' + AppConfig.gameID + lang + ' .close-btn');

        if (this.domLink && this.closeBtnDOM) {
            this.closeBtnDOM.cursor = "pointer";
            // this.visible = this.gameModel.communictionService.shouldShowTutorial;

            this.closeBtnDOM.addEventListener('pointerup', () => {
                // this.gameModel.sendMessage(EMessages.SND_TUTORIAL_END);
                
                this.gameModel.showRules = false;
                this.visible = false;
            });
    
/*             this.gameModel.communictionService.tutorialStatusUpdated.add((shouldShow) => {
                this.visible = shouldShow;
            }); */

            this.gameModel.rulesStatusUpdated.add((shouldShow) => {
                this.visible = shouldShow;
            })
        }
    }

    /**
     * @type {boolean}
     */
    get visible(){ return this._visible}

    set visible(value) {
        if (this._visible === value) return
        this._visible === value;
        // this.domLink.style.display = value ? "flex" : "none";



        if (value) {
            this.domLink.style.display = "flex";
            this.domLink.style.opacity = 0.3;
            gsap.to(this.domLink.style, {
                opacity: 1,
                duration: 1,
                ease: 'power3.out'
              });
        } else {
            gsap.to(this.domLink.style, {
                opacity: 0,
                duration: 1,
                ease: 'power3.out',
                onComplete: () => {
                    this.domLink.style.display = "none";
              },
              });
        }
 
    }

}

export default ReglesDOM