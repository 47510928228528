import SpriteCommon from "../common/SpriteCommon";
import Fireworks from "./Fireworks";

class Particle extends SpriteCommon{
    /**
     * 
     * @param {Fireworks} fireworks 
     * @param {string} resurceName 
     * @param {number} angle - direction of the particle
     * @param {number} speedAbs - absolute aspeed
     * @param {number} lifeTime - life in frames
     */
    constructor(    fireworks,
                    resurceName,
                    posX,
                    posY,
                    angle, 
                    speedAbs, 
                    rSpeed,
                    sc = 1,
                    lifeTime = 200){
        super(resurceName);
        this.fireworks = fireworks;
        this.xp = posX;
        this.yp = posY;
        this.displaceX = 0;
        this.displaceY = 0;
        this.x = this.xp + this.displaceX;
        this.y = this.xp + this.displaceY;
        this.lifeTime = lifeTime;
        this.gX = 0;
        this.gY = 0.1;
        this.rSpeed = rSpeed;
        this.sc = sc;
        this.speedX = speedAbs * Math.sin(angle);
        this.speedY = speedAbs * Math.cos(angle);
        this.t = 0;
        this.anchor.set(0.5, 0.5);
        this.rotation = Math.PI;
        this.jumpSpeed = (1 - Math.random() * Math.random()) / 4;
        // this.jumpSpeed = 0.25;
        // this.scale.set(0.2, 0.2);

    }

    update() {
        this.t++;
        const progress = this.t / this.lifeTime;
        this.gY *= 0.92
        this.speedX += this.gX;
        this.speedY += this.gY;
        this.xp += this.speedX;
        this.yp += this.speedY;
        this.displaceX = Math.cos(this.jumpSpeed * this.t) * (1 - progress) * 10;
        this.displaceY = 0;
        this.x = this.xp + this.displaceX;
        this.y = this.yp + this.displaceY;


        
        let crrScale;;

        if (progress < 0.2) {
            crrScale = Particle.clamp(progress / 0.2);
        } else {
            crrScale = Particle.clamp(1 - (progress - 0.2) * 0.5);
        }
        this.scale.set(crrScale * this.sc);
        // this.alpha = 0.7;

        // this.rotation += this.rSpeed;
        this.rotation = Math.sin(this.t / 10) * 0.2;
    }

    static getScaleByT(t=0) {

    }

    static randRange(min, max) {
        if (min > max) {
            throw new Error('min must be less than or equal to max');
        }
    
        // Generate a random number within the specified range
        return Math.random() * (max - min) + min;
    }

    static clamp(a, min = 0, max = 1) {
        return Math.max(Math.min(min, max), Math.min(a, Math.max(min, max)))
    }
}

export default Particle