import { init} from "./init";
import packageJSON from '../package.json';
import EGameNames from "./config/EGameNames";

window.appversion = packageJSON.version;
document.title = `${packageJSON.description} v${packageJSON.version}`;


const game = init();







