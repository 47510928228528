import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, browserSessionPersistence } from "firebase/auth";
import CommunicationService from './CommunicationService';
class AnalyticsService {

    static init() {
        return
        const firebaseConfig = {
            apiKey: "AIzaSyDtBTfZdQ754plzXyBU70jJ0Gzy3z00WrE",
            authDomain: "sceneitwinit.firebaseapp.com",
            projectId: "sceneitwinit",
            storageBucket: "sceneitwinit.appspot.com",
            messagingSenderId: "433601327603",
            appId: "1:433601327603:web:79f801a8a50c1f80af2286",
            measurementId: "G-J6W1Y351PS"
          };
        AnalyticsService.app = initializeApp(firebaseConfig);
        AnalyticsService.analytics = getAnalytics(AnalyticsService.app);
        
    }


    /**
     * 
     * @param {string} tag 
     */
    static logEvent(tag) {
        return
        logEvent(AnalyticsService.analytics, tag);
        console.log("-> Firebase : " + tag );
        // CommunicationService.logMessage(tag, "Firebase");

    }
}

export default AnalyticsService