import { AppConfig } from "../config/AppConfig";

class ResourceList {
}
ResourceList.LOGO = `./assets/logo.png`;
ResourceList.CART = `./assets/cart.png`;
ResourceList.CART_OVER_EN = `./assets/cart-over_en.png`;
ResourceList.CART_OVER_ZH = `./assets/cart-over_zh.png`;

ResourceList.START_BUTTON_EN = `./assets/start-button_en.png`;
ResourceList.START_BUTTON_HOVER_EN = `./assets/start-button-hover_en.png`;
ResourceList.START_BUTTON_DOWN_EN = `./assets/start-button-down_en.png`;

ResourceList.START_BUTTON_ZH = `./assets/start-button_zh.png`;
ResourceList.START_BUTTON_HOVER_ZH = `./assets/start-button-hover_zh.png`;
ResourceList.START_BUTTON_DOWN_ZH = `./assets/start-button-down_zh.png`;


ResourceList.CONVEYOR = `./assets/bg/conveyor.png`;
ResourceList.CONVEYOR_LIGHT_LEFT = `./assets/bg/conveyor-light_left.png`;
ResourceList.SPLASH_EN = `./assets/splash_en.png`;
ResourceList.SPLASH_ZH = `./assets/splash_zh.png`;
ResourceList.SPLASH_LEFT = `./assets/splash-left.png`;
ResourceList.SPLASH_RIGHT = `./assets/splash-right.png`;
ResourceList.SPLASH_BOX = `./assets/splash-box.png`;
ResourceList.SPLASH_LOGO_EN = `./assets/splash-logo_en.png`;
ResourceList.SPLASH_LOGO_ZH = `./assets/splash-logo_zh.png`;

ResourceList.ITEM_MAGNET = `./assets/items/item-magnet.png`;
ResourceList.ITEM_SPEEDUP = `./assets/items/item-speedup.png`;
ResourceList.ITEM_TIMEPLUS = `./assets/items/item-timeplus.png`;
ResourceList.ITEM_TIMEMINUS = `./assets/items/item-timeminus.png`;
ResourceList.ITEM_BAD = `./assets/items/item-bad.png`;

ResourceList.TIMER_BASE = `./assets/timer-base.png`;
ResourceList.TIMER_PROGRESS_MIDDLE = `./assets/timer-progress-middle.png`;
ResourceList.TIMER_PROGRESS_RIGHT = `./assets/timer-progress-right.png`;
ResourceList.TIMER_PROGRESS_ARROW = `./assets/timer-arrow.png`;

ResourceList.PRGS_CAPSULE_BG = `./assets/progress/capsule-bg.png`;
ResourceList.PRGS_CAPSULE_FLARE = `./assets/progress/capsule-flare.png`;
ResourceList.PRGS_CAPSULE_SHADOW = `./assets/progress/capsule-shadow.png`;
ResourceList.PRGS_DISK_EXTRA_MAGNET = `./assets/progress/disk-extra-magnet.png`;
ResourceList.PRGS_DISK_EXTRA_SPEEDUP = `./assets/progress/disk-extra-speedup.png`;
ResourceList.PRGS_DISK_TIMER = `./assets/progress/disk-timer.png`;

ResourceList.MSC_INFOBOX_BG = `./assets/misc/infobox-bg.png`;
ResourceList.MSC_COIN = `./assets/misc/coin.png`;
ResourceList.MSC_STAR = `./assets/misc/star.png`;
ResourceList.MSC_MINUS = `./assets/misc/minus.png`;
ResourceList.MSC_PLUS = `./assets/misc/plus.png`;
ResourceList.MSC_STAR4 = `./assets/misc/star4.png`;
ResourceList.MSC_DROP = `./assets/misc/drop.png`;
ResourceList.MSC_SHIMMER = `./assets/misc/shimmer.png`;
ResourceList.MSC_SPEEDLINE = `./assets/misc/speedline.png`;
ResourceList.MSC_FADE_CIRCLE = `./assets/misc/fade_circle.png`;
ResourceList.MSC_CIRCLE = `./assets/misc/circle.png`;
ResourceList.MSC_BTN_MUTE = `./assets/misc/btn_mute.png`;
ResourceList.MSC_BTN_UNMUTE = `./assets/misc/btn_unmute.png`;
ResourceList.MSC_BTN_CLOSE = `./assets/misc/btn_close.png`;
ResourceList.MSC_TOP_BANNER = `./assets/misc/top-banner.png`;
ResourceList.MSC_TOP_BANNER_AD_1 = `./assets/misc/top-banner-ad1.png`;
ResourceList.MSC_MIST = `./assets/misc/mist.png`;
ResourceList.MSC_SPEEDUP_EFFECT = `./assets/misc/speedup-effect.png`;
ResourceList.MSC_MAGNET_EFFECT = `./assets/misc/magnet-effect.png`;
ResourceList.MSC_SPEEDUP_CART_EFFECT = `./assets/misc/speedup-cart-effect.png`;
ResourceList.MSC_CART_CLICKER = `./assets/misc/cart-click.png`;
ResourceList.MSC_CART_CLICKER_LEFT_EN = `./assets/misc/cart-click-left_en.png`;
ResourceList.MSC_CART_CLICKER_CENTER_EN = `./assets/misc/cart-click-center_en.png`;
ResourceList.MSC_CART_CLICKER_RIGHT_EN = `./assets/misc/cart-click-right_en.png`;
ResourceList.MSC_CART_CLICKER_LEFT_ZH = `./assets/misc/cart-click-left_zh.png`;
ResourceList.MSC_CART_CLICKER_CENTER_ZH = `./assets/misc/cart-click-center_zh.png`;
ResourceList.MSC_CART_CLICKER_RIGHT_ZH = `./assets/misc/cart-click-right_zh.png`;


ResourceList.MSC_BTN_REGLES_EN = `./assets/misc/btn-regles_en.png`;
ResourceList.MSC_BTN_REGLES_ZH = `./assets/misc/btn-regles_zh.png`;

ResourceList.COUNTDOWN_1_EN = `./assets/countdown/count1_en.png`;
ResourceList.COUNTDOWN_1_ZH = `./assets/countdown/count1_zh.png`;
ResourceList.COUNTDOWN_2_EN = `./assets/countdown/count2_en.png`;
ResourceList.COUNTDOWN_2_ZH = `./assets/countdown/count2_zh.png`;
ResourceList.COUNTDOWN_3_EN = `./assets/countdown/count3_en.png`;
ResourceList.COUNTDOWN_3_ZH = `./assets/countdown/count3_zh.png`;
ResourceList.COUNTDOWN_4_EN = `./assets/countdown/count4_en.png`;
ResourceList.COUNTDOWN_4_ZH = `./assets/countdown/count4_zh.png`;
ResourceList.COUNTDOWN_GO_EN = `./assets/countdown/count-go_en.png`;
ResourceList.COUNTDOWN_GO_ZH = `./assets/countdown/count-go_zh.png`;

ResourceList.PRD_1_ITEM_00 = `./assets/skins/products/products1/item-00.png`;
ResourceList.PRD_1_ITEM_01 = `./assets/skins/products/products1/item-01.png`;
ResourceList.PRD_1_ITEM_02 = `./assets/skins/products/products1/item-02.png`;
ResourceList.PRD_1_ITEM_03 = `./assets/skins/products/products1/item-03.png`;
ResourceList.PRD_1_ITEM_04 = `./assets/skins/products/products1/item-04.png`;
ResourceList.PRD_1_ITEM_05 = `./assets/skins/products/products1/item-05.png`;
ResourceList.PRD_1_ITEM_06 = `./assets/skins/products/products1/item-06.png`;
ResourceList.PRD_1_ITEM_07 = `./assets/skins/products/products1/item-07.png`;
ResourceList.PRD_1_ITEM_08 = `./assets/skins/products/products1/item-08.png`;
ResourceList.PRD_1_ITEM_09 = `./assets/skins/products/products1/item-09.png`;
ResourceList.PRD_1_ITEM_10 = `./assets/skins/products/products1/item-10.png`;
ResourceList.PRD_1_ITEM_11 = `./assets/skins/products/products1/item-11.png`;
ResourceList.PRD_1_ITEM_12 = `./assets/skins/products/products1/item-12.png`;
ResourceList.PRD_1_ITEM_13 = `./assets/skins/products/products1/item-13.png`;
ResourceList.PRD_1_ITEM_14 = `./assets/skins/products/products1/item-14.png`;
ResourceList.PRD_1_ITEM_15 = `./assets/skins/products/products1/item-15.png`;
ResourceList.PRD_1_ITEM_16 = `./assets/skins/products/products1/item-16.png`;
ResourceList.PRD_1_ITEM_17 = `./assets/skins/products/products1/item-17.png`;
ResourceList.PRD_1_ITEM_18 = `./assets/skins/products/products1/item-18.png`;
ResourceList.PRD_1_ITEM_19 = `./assets/skins/products/products1/item-19.png`;
ResourceList.PRD_1_ITEM_20 = `./assets/skins/products/products1/item-20.png`;
ResourceList.PRD_1_ITEM_21 = `./assets/skins/products/products1/item-21.png`;
ResourceList.PRD_1_ITEM_22 = `./assets/skins/products/products1/item-22.png`;
ResourceList.PRD_1_ITEM_23 = `./assets/skins/products/products1/item-23.png`;


/* ResourceList.PRD_1_ITEM_00,
ResourceList.PRD_1_ITEM_01,
ResourceList.PRD_1_ITEM_02,
ResourceList.PRD_1_ITEM_03,
ResourceList.PRD_1_ITEM_04,
ResourceList.PRD_1_ITEM_05,
ResourceList.PRD_1_ITEM_06,
ResourceList.PRD_1_ITEM_07,
ResourceList.PRD_1_ITEM_08,
ResourceList.PRD_1_ITEM_09,
ResourceList.PRD_1_ITEM_10,
ResourceList.PRD_1_ITEM_11,
ResourceList.PRD_1_ITEM_12,
ResourceList.PRD_1_ITEM_13,
ResourceList.PRD_1_ITEM_14,
ResourceList.PRD_1_ITEM_15,
ResourceList.PRD_1_ITEM_16,
ResourceList.PRD_1_ITEM_17,
ResourceList.PRD_1_ITEM_18,
ResourceList.PRD_1_ITEM_19,
ResourceList.PRD_1_ITEM_20,
ResourceList.PRD_1_ITEM_21,
ResourceList.PRD_1_ITEM_22,
ResourceList.PRD_1_ITEM_23, */


ResourceList.BG_1_WALL = `./assets/skins/backs/game1/wall-tile.png`;
ResourceList.BG_1_FLOOR = `./assets/skins/backs/game1/floor-tile.png`;
ResourceList.BG_1_DOOR = `./assets/skins/backs/game1/door.png`;
ResourceList.BG_1_OBJ_LEFT_1 = `./assets/skins/backs/game1/object_left_1.png`;
ResourceList.BG_1_OBJ_RIGHT_1 = `./assets/skins/backs/game1/object_right_1.png`;
/* ResourceList.BG_1_OBJ_CLOUD_1 = `./assets/skins/backs/game1/cloud-big.png`;
ResourceList.BG_1_OBJ_CLOUD_2 = `./assets/skins/backs/game1/cloud-2.png`;
ResourceList.BG_1_OBJ_CLOUD_3 = `./assets/skins/backs/game1/cloud-3.png`;
ResourceList.BG_1_OBJ_CLOUD_4 = `./assets/skins/backs/game1/cloud-4.png`;
ResourceList.BG_1_OBJ_CLOUD_5 = `./assets/skins/backs/game1/cloud-5.png`;
ResourceList.BG_1_OBJ_CLOUD_6 = `./assets/skins/backs/game1/cloud-6.png`; */

ResourceList.BG_1_ELEMENT_1 = `./assets/skins/backs/game1/element-left_1.png`;
ResourceList.BG_1_ELEMENT_2 = `./assets/skins/backs/game1/element-left_2.png`;
ResourceList.BG_1_ELEMENT_3 = `./assets/skins/backs/game1/element-left_3.png`;
ResourceList.BG_1_ELEMENT_4 = `./assets/skins/backs/game1/element-left_4.png`;
ResourceList.BG_1_ELEMENT_5 = `./assets/skins/backs/game1/element-left_5.png`;
ResourceList.BG_1_ELEMENT_6 = `./assets/skins/backs/game1/element-left_6.png`;
ResourceList.BG_1_ELEMENT_7 = `./assets/skins/backs/game1/element-left_7.png`;
ResourceList.BG_1_ELEMENT_8 = `./assets/skins/backs/game1/element-tor.png`;




ResourceList.BG_1_OBJ_CLOUD_1 = `./assets/clouds/clouds_blue-01.png`;
ResourceList.BG_1_OBJ_CLOUD_2 = `./assets/clouds/clouds_blue-02.png`;
ResourceList.BG_1_OBJ_CLOUD_3 = `./assets/clouds/clouds_blue-03.png`;
ResourceList.BG_1_OBJ_CLOUD_4 = `./assets/clouds/clouds_blue-04.png`;
ResourceList.BG_1_OBJ_CLOUD_5 = `./assets/clouds/clouds-05.png`;
ResourceList.BG_1_OBJ_CLOUD_6 = `./assets/clouds/clouds_blue-06.png`;
ResourceList.BG_1_OBJ_CLOUD_7 = `./assets/clouds/clouds_blue-07.png`;
ResourceList.BG_1_OBJ_CLOUD_8 = `./assets/clouds/clouds_blue-08.png`;
ResourceList.BG_1_OBJ_CLOUD_9 = `./assets/clouds/clouds_blue-09.png`;

ResourceList.BG_1_BUBBLE_1 = `./assets/clouds/bubbles-02.png`;
ResourceList.BG_1_BUBBLE_2 = `./assets/clouds/bubbles-03.png`;
ResourceList.BG_1_BUBBLE_3 = `./assets/clouds/bubbles-04.png`;

// ResourceList.BG_1_BUBBLE = `./assets/skins/backs/game1/bubble2.png`;
ResourceList.BG_1_HEART = `./assets/skins/backs/game1/heart.png`;

ResourceList.BG_2_WALL = `./assets/skins/backs/game2/wall-tile.png`;
ResourceList.BG_2_FLOOR = `./assets/skins/backs/game2/floor-tile.png`;
ResourceList.BG_2_DOOR = `./assets/skins/backs/game2/door.png`;

ResourceList.BG_2_OBJ_LEFT_1 = `./assets/skins/backs/game2/object_left_1.png`;
ResourceList.BG_2_OBJ_RIGHT_1 = `./assets/skins/backs/game2/object_right_1.png`;

ResourceList.BG_3_WALL = `./assets/skins/backs/game3/wall-tile.png`;
ResourceList.BG_3_FLOOR = `./assets/skins/backs/game3/floor-tile.png`;
ResourceList.BG_3_DOOR = `./assets/skins/backs/game3/door.png`;
ResourceList.BG_3_OBJ_LEFT_1 = `./assets/skins/backs/game3/door.png`;

ResourceList.BG_3_OBJ_LEFT_1 = `./assets/skins/backs/game3/object_left_1.png`;
ResourceList.BG_3_OBJ_LEFT_2 = `./assets/skins/backs/game3/object_left_2.png`;
ResourceList.BG_3_OBJ_RIGHT_1 = `./assets/skins/backs/game3/object_right_1.png`;
ResourceList.BG_3_OBJ_RIGHT_2 = `./assets/skins/backs/game3/object_right_2.png`;
ResourceList.BG_3_OBJ_RIGHT_3 = `./assets/skins/backs/game3/object_right_3.png`;

ResourceList.BG_4_WALL = `./assets/skins/backs/game4/wall-tile.png`;
ResourceList.BG_4_FLOOR = `./assets/skins/backs/game4/floor-tile.png`;
ResourceList.BG_4_DOOR = `./assets/skins/backs/game4/door.png`;


ResourceList.MSC_1_BANNER = `./assets/skins/logos/logo_1_cinepex.png`;
ResourceList.MSC_2_BANNER = `./assets/skins/logos/logo_2_cine.png`;
ResourceList.MSC_3_BANNER_EN = `./assets/skins/logos/logo_3_scotia_en.png`;
ResourceList.MSC_3_BANNER_ZH = `./assets/skins/logos/logo_3_scotia_zh.png`;
// ResourceList.MSC_4_BANNER = `./assets/skins/logos/logo_4_scene.png`;
ResourceList.MSC_4_BANNER_EN = `./assets/skins/logos/logo_4_scene_en.png`;
ResourceList.MSC_4_BANNER_ZH = `./assets/skins/logos/logo_4_scene_zh.png`;



ResourceList.PWUP_PRESENT = `./assets/products/present.png`;
ResourceList.PWUP_WATCH_MINUS = `./assets/products/watch-minus.png`;
ResourceList.PWUP_WATCH_PLUS = `./assets/products/watch-plus.png`;
ResourceList.PWUP_LIGHTING = `./assets/products/lighting.png`;

ResourceList.EFFECTS = `./assets/effects.json`;

ResourceList.MSC_BTN_REGLES = `./assets/misc/btn-regles.png`;

ResourceList.CUSTOM_RESOURCES = [];

ResourceList.LIST_GAME1 = [
    ResourceList.BG_1_DOOR,
    ResourceList.BG_1_OBJ_LEFT_1,
    ResourceList.BG_1_OBJ_RIGHT_1,

    ResourceList.BG_1_OBJ_CLOUD_1,
    ResourceList.BG_1_OBJ_CLOUD_2,
    ResourceList.BG_1_OBJ_CLOUD_3,
    ResourceList.BG_1_OBJ_CLOUD_4,
    ResourceList.BG_1_OBJ_CLOUD_5,
    ResourceList.BG_1_OBJ_CLOUD_6,
    ResourceList.BG_1_OBJ_CLOUD_7,
    ResourceList.BG_1_OBJ_CLOUD_8,
    ResourceList.BG_1_OBJ_CLOUD_9,

    ResourceList.BG_1_ELEMENT_1,
    ResourceList.BG_1_ELEMENT_2,
    ResourceList.BG_1_ELEMENT_3,
    ResourceList.BG_1_ELEMENT_4,
    ResourceList.BG_1_ELEMENT_5,
    ResourceList.BG_1_ELEMENT_6,
    ResourceList.BG_1_ELEMENT_7,
    ResourceList.BG_1_ELEMENT_8,

    ResourceList.BG_1_BUBBLE_1,
    ResourceList.BG_1_BUBBLE_2,
    ResourceList.BG_1_BUBBLE_3,
    ResourceList.BG_1_HEART,

    ResourceList.MSC_1_BANNER,  

    ResourceList.PRD_1_ITEM_00,
    ResourceList.PRD_1_ITEM_01,
    ResourceList.PRD_1_ITEM_02,
    ResourceList.PRD_1_ITEM_03,
    ResourceList.PRD_1_ITEM_04,
    ResourceList.PRD_1_ITEM_05,
    ResourceList.PRD_1_ITEM_06,
    ResourceList.PRD_1_ITEM_07,
    ResourceList.PRD_1_ITEM_08,
    ResourceList.PRD_1_ITEM_09,
    ResourceList.PRD_1_ITEM_10,
    ResourceList.PRD_1_ITEM_11,
    ResourceList.PRD_1_ITEM_12,
    ResourceList.PRD_1_ITEM_13,
    ResourceList.PRD_1_ITEM_14,
    ResourceList.PRD_1_ITEM_15,
    ResourceList.PRD_1_ITEM_16,
    ResourceList.PRD_1_ITEM_17,
    ResourceList.PRD_1_ITEM_18,
    ResourceList.PRD_1_ITEM_19,
    ResourceList.PRD_1_ITEM_20,
    ResourceList.PRD_1_ITEM_21,
    ResourceList.PRD_1_ITEM_22,
    ResourceList.PRD_1_ITEM_23
]

ResourceList.PRODUCTS_10_GAME1 = [
    ResourceList.PRD_1_ITEM_10,
    ResourceList.PRD_1_ITEM_11,
    ResourceList.PRD_1_ITEM_12,
    ResourceList.PRD_1_ITEM_13,
    ResourceList.PRD_1_ITEM_14,
    ResourceList.PRD_1_ITEM_15,
    ResourceList.PRD_1_ITEM_16,
    ResourceList.PRD_1_ITEM_17,
    ResourceList.PRD_1_ITEM_18,
    ResourceList.PRD_1_ITEM_19
];
ResourceList.PRODUCTS_20_GAME1 = [
    ResourceList.PRD_1_ITEM_00,
    ResourceList.PRD_1_ITEM_01,
    ResourceList.PRD_1_ITEM_02,
    ResourceList.PRD_1_ITEM_03,
    ResourceList.PRD_1_ITEM_04,
    ResourceList.PRD_1_ITEM_05,
    ResourceList.PRD_1_ITEM_06,
    ResourceList.PRD_1_ITEM_07,
    ResourceList.PRD_1_ITEM_08,
    ResourceList.PRD_1_ITEM_09,

    ResourceList.PRD_1_ITEM_20,
    ResourceList.PRD_1_ITEM_21,
    ResourceList.PRD_1_ITEM_22,
    ResourceList.PRD_1_ITEM_23,
];



ResourceList.LIST_LOCALE_EN = [
    ResourceList.MSC_BTN_REGLES_EN,
    ResourceList.START_BUTTON_EN,
    ResourceList.START_BUTTON_HOVER_EN,
    ResourceList.START_BUTTON_DOWN_EN,
    ResourceList.COUNTDOWN_GO_EN,
    ResourceList.COUNTDOWN_3_EN,
    ResourceList.COUNTDOWN_2_EN,
    ResourceList.COUNTDOWN_1_EN,
    ResourceList.SPLASH_LOGO_EN,
    ResourceList.CART_OVER_EN,
    ResourceList.MSC_CART_CLICKER_LEFT_EN,
    ResourceList.MSC_CART_CLICKER_CENTER_EN,
    ResourceList.MSC_CART_CLICKER_RIGHT_EN,
    ResourceList.SPLASH_EN
    
];

ResourceList.LIST_LOCALE_ZH = [
    ResourceList.MSC_BTN_REGLES_ZH,
    ResourceList.START_BUTTON_ZH,
    ResourceList.START_BUTTON_HOVER_ZH,
    ResourceList.START_BUTTON_DOWN_ZH,
    ResourceList.COUNTDOWN_GO_ZH,
    ResourceList.COUNTDOWN_3_ZH,
    ResourceList.COUNTDOWN_2_ZH,
    ResourceList.COUNTDOWN_1_ZH,
    ResourceList.SPLASH_LOGO_ZH,
    ResourceList.CART_OVER_ZH,
    ResourceList.MSC_CART_CLICKER_LEFT_ZH,
    ResourceList.MSC_CART_CLICKER_CENTER_ZH,
    ResourceList.MSC_CART_CLICKER_RIGHT_ZH,
    ResourceList.SPLASH_ZH
];

ResourceList.LIST = [
    ResourceList.CART,
    // ResourceList.CART_OVER,
    // ResourceList.SPLASH,
    ResourceList.SPLASH_LEFT,
    ResourceList.SPLASH_RIGHT,
    ResourceList.SPLASH_BOX,
    // ResourceList.SPLASH_LOGO,
    ResourceList.ITEM_BAD,

    ResourceList.CONVEYOR,
    ResourceList.CONVEYOR_LIGHT_LEFT,

    ResourceList.ITEM_MAGNET,
    ResourceList.ITEM_SPEEDUP,
    ResourceList.ITEM_TIMEPLUS,
    ResourceList.ITEM_TIMEMINUS,
    // ResourceList.EFFECTS,
    ResourceList.TIMER_BASE,
    ResourceList.TIMER_PROGRESS_MIDDLE,
    ResourceList.TIMER_PROGRESS_RIGHT,
    ResourceList.TIMER_PROGRESS_ARROW,

    ResourceList.PRGS_CAPSULE_BG,
    ResourceList.PRGS_CAPSULE_FLARE,
    ResourceList.PRGS_CAPSULE_SHADOW,
    ResourceList.PRGS_DISK_EXTRA_MAGNET,
    ResourceList.PRGS_DISK_EXTRA_SPEEDUP,
    ResourceList.PRGS_DISK_TIMER,

    ResourceList.MSC_INFOBOX_BG,
    ResourceList.MSC_COIN,
    ResourceList.MSC_STAR,
    ResourceList.MSC_BTN_MUTE,
    ResourceList.MSC_BTN_UNMUTE,
    ResourceList.MSC_BTN_CLOSE,
    ResourceList.MSC_TOP_BANNER,
    ResourceList.MSC_TOP_BANNER_AD_1,
    ResourceList.MSC_MIST,
    ResourceList.MSC_SPEEDUP_EFFECT,
    ResourceList.MSC_MAGNET_EFFECT,
    ResourceList.MSC_SPEEDUP_CART_EFFECT,
    ResourceList.MSC_CART_CLICKER,
    // ResourceList.MSC_CART_CLICKER_LEFT,
    // ResourceList.MSC_CART_CLICKER_CENTER,
    // ResourceList.MSC_CART_CLICKER_RIGHT,
    // ResourceList.MSC_BTN_REGLES,

    // ResourceList.COUNTDOWN_1,
    // ResourceList.COUNTDOWN_2,
    // ResourceList.COUNTDOWN_3,
    // ResourceList.COUNTDOWN_4,
    // ResourceList.COUNTDOWN_GO,

    ...ResourceList.CUSTOM_RESOURCES,
];


const skinArray = ResourceList["LIST_GAME" + AppConfig.gameID];
ResourceList.LIST = ResourceList.LIST.concat(skinArray);
const lang = AppConfig.lang;
const engRes = ResourceList.LIST_LOCALE_EN;
const frRes = ResourceList.LIST_LOCALE_ZH;
const langArray = ResourceList["LIST_LOCALE_" + AppConfig.lang];
ResourceList.LIST = ResourceList.LIST.concat(langArray);

ResourceList.PRODUCTS_10_LIST = ResourceList["PRODUCTS_10_GAME" + AppConfig.gameID];
ResourceList.PRODUCTS_20_LIST = ResourceList["PRODUCTS_20_GAME" + AppConfig.gameID];

export default ResourceList;
