import * as PIXI from 'pixi.js';
import { AppConfig } from "../../config/AppConfig";
import Point3D from "../../model/pseudo3ds/Point3D";

class Utils3D {

    
    static updateConveyorCorners() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { worldSize, conveyorY, conveyorWidth, focalLength, horyzontPos, zDeep, cameraOffsetX, zCartPosition} = AppConfig.settings3D;

        const w = gameWidth;
        const h = gameHeight;
        const centerX = w / 2;

        const marginFactor = 1.2
        
        const topLeft3D = new Point3D(null, - marginFactor * worldSize * conveyorWidth / 2, conveyorY * worldSize, zDeep);
        const topRight3D = new Point3D(null, marginFactor * worldSize * conveyorWidth / 2, conveyorY * worldSize, zDeep);
        const bottomLeft3D = topLeft3D.clone();
        bottomLeft3D.z = zCartPosition ;
        const bottomRight3D = topRight3D.clone();
        bottomRight3D.z = zCartPosition;

        const lampFrontLeft3D = topLeft3D.clone();
        lampFrontLeft3D.z =  zCartPosition + (zDeep - zCartPosition) * 0.05;
        const lampFrontRIght3D = topRight3D.clone();
        lampFrontRIght3D.z =  lampFrontLeft3D.z;
        // lampFrontRIght3D.x *= 1.2;
        const lampBacktLeft3D = topLeft3D.clone();
        lampBacktLeft3D.z =  zCartPosition + (zDeep - zCartPosition) * 0.7;
        const lampBacktRIght3D = topRight3D.clone();
        lampBacktRIght3D.z = lampBacktLeft3D.z;
        

        const topLeft = new PIXI.Point();
        const topRight = new PIXI.Point();
        const bottomLeft = new PIXI.Point();
        const bottomRight = new PIXI.Point();

        const lampFrontLeft = new PIXI.Point();
        const lampFrontRIght = new PIXI.Point();
        const lampBacktLeft = new PIXI.Point();
        const lampBacktRIght = new PIXI.Point();


        const setPoint2Dby3D = (point3D, point2D) => {
            point2D.x = centerX + (point3D.x - cameraOffsetX) / (point3D.z + focalLength);
            point2D.y = h * horyzontPos + point3D.y / (point3D.z + focalLength);
        }

        setPoint2Dby3D(topLeft3D, topLeft);
        setPoint2Dby3D(topRight3D, topRight);
        setPoint2Dby3D(bottomLeft3D, bottomLeft);
        setPoint2Dby3D(bottomRight3D, bottomRight);


        setPoint2Dby3D(lampFrontLeft3D, lampFrontLeft);
        setPoint2Dby3D(lampFrontRIght3D, lampFrontRIght);
        setPoint2Dby3D(lampBacktLeft3D, lampBacktLeft);
        setPoint2Dby3D(lampBacktRIght3D, lampBacktRIght);      

        const result = { topLeft: topLeft, topRight: topRight, 
            bottomLeft: bottomLeft, bottomRight: bottomRight,
            lampFrontLeft, lampFrontRIght, lampBacktLeft, lampBacktRIght
        };
        
        
        return result


    }

    /**
     * @return {{left: Number, righ: Number}} 
     */
    static getConveyorBottomCorners() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        const { worldSize, conveyorY, conveyorWidth, focalLength, horyzontPos, zDeep, cameraOffsetX, zCartPosition} = AppConfig.settings3D;
        
        const w = gameWidth;
        const h = gameHeight;
        const centerX = w / 2;
        
        const marginFactor = 1.2
        const bottomLeft3D = new Point3D(null, - marginFactor * worldSize * conveyorWidth / 2, conveyorY * worldSize, zCartPosition - 1);
        const bottomRight3D = new Point3D(null, marginFactor * worldSize * conveyorWidth / 2, conveyorY * worldSize, zCartPosition - 1);

        const leftPos = centerX + (bottomLeft3D.x - cameraOffsetX) / (bottomLeft3D.z + focalLength);
        // bottomLeft.y = h * horyzontPos + bottomLeft3D.y / (bottomLeft3D.z + focalLength);

        const rightPos = centerX + (bottomRight3D.x - cameraOffsetX) / (bottomRight3D.z + focalLength);
        // bottomRight.y = h * horyzontPos + bottomRight3D.y / (bottomRight3D.z + focalLength);

        return {left:leftPos, right:rightPos}
    }

    static get3DXByPosInRow(pos) {
        const {worldSize, conveyorWidth, productLineOnConveyor} = AppConfig.settings3D;

        const f = 0.5 + pos * (productLineOnConveyor / 2);
        return f * conveyorWidth * worldSize - worldSize * conveyorWidth / 2;
    } 

    static getXByPoint3D(point3D) {
        const { gameWidth } = AppConfig.settings;
        const {focalLength, cameraOffsetX} = AppConfig.settings3D;
        const w = gameWidth;
        const centrX = w / 2;
        const xPos = centrX + (point3D.x - cameraOffsetX) / (point3D.z + focalLength);
        return xPos
    }

    static getYByPoint3D(point3D) {
        const { gameHeight } = AppConfig.settings;
        const {focalLength, horyzontPos} = AppConfig.settings3D;
        const h = gameHeight;
        const centrY = h * horyzontPos;
        const yPos = centrY + point3D.y / (point3D.z + focalLength);
        return yPos
    }

    static get3DZByPoint2D(x, y, y3D) {
        const { gameWidth } = AppConfig.settings;
        const {focalLength, cameraOffsetX} = AppConfig.settings3D;
        const centerX = gameWidth / 2;
        const z3D = (y - y3D) / (x - centerX) - focalLength;
    
        return z3D;
    }

    static getCart3DZByPoint2DY2(y) {
        const { gameWidth } = AppConfig.settings;
        const {focalLength, conveyorY} = AppConfig.settings3D;
        const centerX = gameWidth / 2;
        const z3D = (y - conveyorY) / (0 - centerX) - focalLength;   
        return z3D;
    }
    static getCart3DZByPoint2DY(y) {
        const { gameHeight } = AppConfig.settings;
        const {focalLength, conveyorY, horyzontPos, worldSize} = AppConfig.settings3D;
        // const z3D = (y - conveyorY) / (0 - centerX) - focalLength; 
        const z3D  = (conveyorY * worldSize)  /  (y - gameHeight * horyzontPos) - focalLength  
        return z3D;
    }

    static getCartXPosByPosInRow(pos){
        const {zCartPosition, conveyorY, horyzontPos, worldSize} = AppConfig.settings3D;
        const x3D = Utils3D.get3DXByPosInRow(pos);
        const point3D = new Point3D(null, x3D, conveyorY * worldSize, zCartPosition);
        return Utils3D.getXByPoint3D(point3D);

    }

    static getConveyorWidth3DByRealWidth(realWidth) {
        const { focalLength, cameraOffsetX, zCartPosition } = AppConfig.settings3D;
        const { gameWidth } = AppConfig.settings;
    
        const centerX = gameWidth / 2;
    
        // Inverse the 2D to 3D projection to calculate 3D width
        const leftX3D = (centerX - realWidth / 2 - centerX) * (zCartPosition + focalLength) + cameraOffsetX;
        const rightX3D = (centerX + realWidth / 2 - centerX) * (zCartPosition + focalLength) + cameraOffsetX;
    
        // Calculate the 3D conveyor width as the difference between the 3D x-coordinates
        const conveyorWidth3D = (rightX3D - leftX3D) / AppConfig.settings3D.worldSize;
    
        return conveyorWidth3D;
    }
    

}

export default Utils3D