import SpriteCommon from "../common/SpriteCommon";

class ObjectFlying extends SpriteCommon{
    constructor(texture, targetObject = null) {
        super(texture);
        this.targetObject = targetObject;
        this.t = 0;
        this.shiftX = 0;
        this.shiftY = 0;
        this.rangeX = 400;
        this.rangeY = 10;
        this.axisX = 0;
        this.axisY = 0;
        this.speedX = 0.01;
        this.speedY = 0.05;

        this.alpha = 1;
        this.anchor.set(0.5, 0.5);
        // this.scale.set(0.8);


        this.animate = (diff) => {
            this.t ++;
            const spX = 1 / this.speedX;
            const spY = 1 / this.speedY;
            this.shiftX = Math.sin(this.t / spX) * this.rangeX;
            this.shiftY = Math.cos(this.t / spY) * this.rangeY;

            this.x = this.axisX + this.shiftX;
            this.y = this.axisY + this.shiftY;


        }
    }


}

export default ObjectFlying;