import * as PIXI from 'pixi.js';
import SpriteCommon from './common/SpriteCommon';
import ResourceList from '../../services/ResourceList';
import { AppConfig } from '../../config/AppConfig';
import ResourceService from '../../services/ResourceService';

class StartButton extends PIXI.Sprite {
  constructor() {
      super();

      this.isMobile = AppConfig.isMobileDevice();
      this.cursor = "pointer";
      this.eventMode = "dynamic";

      this.bg = new PIXI.Graphics();
  /*     this.bg.beginFill(0x33cc33); // Green color
      this.bg.drawRoundedRect(0, 0, 150, 70, 10);
      this.bg.endFill(); */

      if (AppConfig.lang == "ZH") {
          this.normalTexture = ResourceService.getTexture(ResourceList.START_BUTTON_ZH);
          this.hoverTexture = ResourceService.getTexture(ResourceList.START_BUTTON_HOVER_ZH);
          this.downTexture = ResourceService.getTexture(ResourceList.START_BUTTON_DOWN_ZH);
          this.buttonLabel = new SpriteCommon(ResourceList.START_BUTTON_ZH);
      } else {
          this.normalTexture = ResourceService.getTexture(ResourceList.START_BUTTON_EN);
          this.hoverTexture = ResourceService.getTexture(ResourceList.START_BUTTON_HOVER_EN);
          this.downTexture = ResourceService.getTexture(ResourceList.START_BUTTON_DOWN_EN);
          this.buttonLabel = new SpriteCommon(ResourceList.START_BUTTON_EN);
      }
      this.buttonLabel.scale.set(0.5);
      this.buttonLabel.anchor.set(0.5);
    

      // this.buttonLabel = new PIXI.Text('Start', { fontSize: 40  , fill: 0xFFFFFF });
      
      // this.buttonLabel.anchor.set(0.5, 0.5);
      this.addChild(this.bg, this.buttonLabel);
      this.cursor = "pointer";

      // this.bg.position.set( - this.bg.width / 2,  - this.bg.height / 2);
      // this.buttonLabel.position.set( - this.buttonLabel.width / 2,  - this.buttonLabel.height / 2);

      this.buttonMode = true;

      this.on('pointerdown', this.onButtonDown.bind(this));
      this.on('pointerup', this.onButtonUp.bind(this));
      this.on('pointerover', this.onButtonOver.bind(this));
      this.on('pointerout', this.onButtonOut.bind(this));
  }

  onButtonDown() {
      if (this.isMobile) {
        //this.emit('startButtonClick');
      } 
      this.buttonLabel.texture = this.downTexture;
      // this.buttonLabel.y = 3;

  }

  onButtonUp() {
    if (!this.isMobile) {
      //this.emit('startButtonClick');
    } 
    this.emit('startButtonClick');
    this.buttonLabel.y = 0;
  }

  onButtonOver() {
    this.buttonLabel.texture = this.hoverTexture;
    // this.buttonLabel.y = -3;
  }
  onButtonOut() {
    this.buttonLabel.texture = this.normalTexture;
    this.buttonLabel.y = 0;
  }
}

export default StartButton
