import { AppConfig } from "./AppConfig";

class Language {

    /**
     * 
     * @param {en:string, fr:string} text 
     * @returns string
     */
    static getText(text) {
        return text[AppConfig.lang.toLowerCase()];
    }

}

Language.OUCH = {en:"OUCH!", zh:"OUCH!"};
Language.SUPER = {en:"SUPER!", zh:"SUPER!"};
Language.INCREDIBLE = {en:"INCREDIBLE!", zh:"INCROYABLE!"};



export default Language;